<template>
  <dotlottie-player
    :class="animationType == 'login' ? '' : 'animation-body'" 
    :src="animation[animationType]"
    autoplay
    loop
  />
</template>

<script>
import * as LottiePlayer from '@dotlottie/player-component'

export default {
  LottiePlayer,
  props:{
    animationType: String,
  },
  data() {
    return {
      animation: {
        'login': 'https://assets8.lottiefiles.com/dotlotties/dlf10_yesjyi6s.lottie',
        'register': 'https://assets4.lottiefiles.com/dotlotties/dlf10_oftzlz1j.lottie',
        'password': 'https://assets9.lottiefiles.com/dotlotties/dlf10_ycyfgw9m.lottie',
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .animation-body{
    max-width: 750px;
  }
</style>